import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  DatePicker,
  Row,
  Select,
  Typography,
  Checkbox,
} from 'antd';
import {
  EuroCircleOutlined,
  MailOutlined,
  ProfileOutlined,
  UserOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import i18n from '../../../assets/language/i18n';
import Colors from '../../../assets/colors/Colors';
import ConstantsLanguages from '../../../constants/ConstantsLanguages';
import ConstantsDate from '../../../constants/ConstantsDate';
import { useGetFiltersInvitationsApiQuery } from '../../../services/invitations';
import { validateEmail } from '../../../utils/Utils';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const styles = {
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: 90
  },
  icon: {
    color: Colors.PRIMARY_COLOR
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular'
  },
  input: {
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  datesRange: {
    width: '100%'
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%'
  },
  select: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white'
  },
};

export default function NewInvitationForm(props) {
  const { RangePicker } = DatePicker;

  const { handleSubmit } = props;
  const { securePaymentData } = props;

  
  const [nameLastname, setNameLastname] = useState('');
  const [emailPay, setEmailPay] = useState('');
  const [hotelCode, setHotelCode] = useState('');
  const [payConcept, setPayConcept] = useState('');
  const [serviceAmount, setServiceAmount] = useState(0);
  const [currency, setCurrency] = useState('');
  const [language, setLanguage] = useState(ConstantsLanguages.ES);
  const [localizator, setLocalizator] = useState('');
  const [setToTerminal, setSetToTerminal] = useState(false);
  useEffect(() => {
    if (securePaymentData) {
      setNameLastname('servicioseguro');
      setEmailPay('servicioseguro@adgtravel.com');
    }
  });
  const [dateInitSend, setDateInitSend] = useState(
    new Date().toLocaleDateString()
  );
  const [dateEndSend, setDateEndSend] = useState(
    new Date().toLocaleDateString()
  );

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const jwtData = useSelector((state) => state.reducerJwtData);

  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  const { data: applyFilters } = useGetFiltersInvitationsApiQuery();
  
  const resetFormValues = () => {
    setCurrency('');
    setEmailPay('');
    setNameLastname('');
    setPayConcept('');
    setServiceAmount('');
    setHotelCode('');
    setLocalizator('');
    setSetToTerminal(false);
    setDateInitSend(new Date().toLocaleDateString());
    setDateEndSend(new Date().toLocaleDateString());
    form.resetFields();
  };

  const handleOk = () => {
    handleSubmit({
      nameLastname,
      email: emailPay,
      currency,
      concept: payConcept,
      language,
      securePaymentData,
      amount: serviceAmount,
      hotelCode,
      dateInitSend,
      dateEndSend,
      localizator,
      setToTerminal
    });
    resetFormValues();
  };

  const hotelsOptions = [];
  applyFilters?.hotels.forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>{i.label}</Text>
      </Option>
    );
  });

  const currencyOptions = [];
  applyFilters?.currencies.forEach((i) => {
    currencyOptions.push(
      <Option key={i.currency} value={i.currency}>
        <Text style={styles.textOptionValue}>{i.currency}</Text>
      </Option>
    );
  });

  const languageOptions = [];
  languageOptions.push(
    <Option key={ConstantsLanguages.ES} value={ConstantsLanguages.ES}>
      <Text style={styles.textOptionValue}>{t('text_language_es')}</Text>
    </Option>
  );
  languageOptions.push(
    <Option key={ConstantsLanguages.EN} value={ConstantsLanguages.EN}>
      <Text style={styles.textOptionValue}>{t('text_language_en')}</Text>
    </Option>
  );

  const paymentLinkInputByClientType = (
    <Form.Item
      name="rangeOfDates"
      rules={[
        {
          required: true,
          message: t('error_empty_field')
        }
      ]}
    >
      <Row>
        <Col span={12}>
          <Text>{i18n.t('text_init_service_date')}</Text>
        </Col>
        <Col span={12}>
          <Text>{i18n.t('text_end_service_date')}</Text>
        </Col>
      </Row>
      <RangePicker
        style={{ ...styles.input, ...styles.datesRange }}
        format={ConstantsDate.DATE_FORMAT_PICKER}
        defaultValue={[
          moment(dateInitSend, ConstantsDate.DATE_FORMAT_PICKER),
          moment(dateEndSend, ConstantsDate.DATE_FORMAT_PICKER)
        ]}
        onChange={(dates) => {
          setDateInitSend(
            dates === null
              ? ''
              : dates[0].format(ConstantsDate.DATE_FORMAT_PICKER)
          );
          setDateEndSend(
            dates === null
              ? ''
              : dates[1].format(ConstantsDate.DATE_FORMAT_PICKER)
          );
        }}
      />
    </Form.Item>
  );

  const isDisabledSubmitModalForm = () => nameLastname.length === 0
      || emailPay.length === 0
      || payConcept.length === 0
      || serviceAmount.length === 0
      || currency.length === 0
      || localizator.length === 0
      || hotelCode.length === 0
      || dateInitSend?.length === 0 || dateEndSend?.length === 0;

  return (
    <Form
      form={form}
      name="normal_login"
      initialValues={{
        remember: true,
        size: 'middle'
      }}
    >
      {securePaymentData
        ? null : ( 
          <Form.Item
            name="nameLastname"
            rules={[
              {
                required: true,
                message: t('error_empty_field')
              }
            ]}
          >
            <Input
              prefix={<UserOutlined style={styles.icon} />}
              placeholder={t('hint_name_lastname')}
              onChange={(text) => {
                setNameLastname(text.target.value);
              }}
              value={nameLastname}
              style={styles.input}
            />
          </Form.Item>
        )}
      {securePaymentData 
        ? null : (
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('error_empty_field')
              }
            ]}
          >
            <Input
              prefix={<MailOutlined style={styles.icon} />}
              placeholder={t('hint_email')}
              onChange={(text) => {
                setEmailPay(text.target.value);
              }}
              type="email"
              value={emailPay}
              style={styles.input}
            />
          </Form.Item>
        )}
      <Form.Item
        name="hotelCode"
        rules={[
          {
            required: true,
            message: t('error_empty_field')
          }
        ]}
      >
        <Select
          showSearch
          defaultActiveFirstOption={false}
          style={styles.select}
          placeholder={
          isCubatourProfile ? t('text_office') : t('hint_hotel_name')
        }
          optionFilterProp="children"
          onChange={(value) => {
            setHotelCode(value);
          }}
          filterOption={(input, option) => option.children.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0}
          value={hotelCode}
        >
          {hotelsOptions}
        </Select>
      </Form.Item>

      <Form.Item
        name="localizator"
        rules={[
          {
            required: true,
            message: t('error_empty_field')
          }
        ]}
      >
        <Input
          prefix={<SearchOutlined style={styles.icon} />}
          placeholder={t('hint_confirmation_reservation_code')}
          onChange={(text) => {
            setLocalizator(text.target.value);
          }}
          value={localizator}
          style={styles.input}
        />
      </Form.Item>

      {paymentLinkInputByClientType}

      <Form.Item
        name="payConcept"
        rules={[
          {
            required: true,
            message: t('error_empty_field')
          }
        ]}
      >
        <TextArea
          prefix={<ProfileOutlined style={styles.icon} />}
          placeholder={t('hint_pay_concept')}
          onChange={(text) => {
            setPayConcept(text.target.value);
          }}
          value={payConcept}
          style={styles.input}
          rows={4}
        />
      </Form.Item>
      <Form.Item
        name="language"
        rules={[
          {
            required: true,
            message: t('error_empty_field')
          }
        ]}
      >
        <Select
          style={styles.select}
          placeholder={t('text_language')}
          optionFilterProp="children"
          defaultValue={ConstantsLanguages.ES}
          onChange={(value) => {
            setLanguage(value);
          }}
          value={language}
        >
          {languageOptions}
        </Select>
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="serviceAmount"
          rules={[
            {
              required: true,
              message: t('error_empty_field')
            }
          ]}
          style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
        >
          <Input
            prefix={<EuroCircleOutlined style={styles.icon} />}
            placeholder={t('hint_amount_service')}
            onChange={(text) => {
              setServiceAmount(text.target.value);
            }}
            value={serviceAmount}
            style={styles.input}
          />
        </Form.Item>
        <Form.Item
          name="currency"
          rules={[
            {
              required: true,
              message: t('error_empty_field')
            }
          ]}
          style={{
            display: 'inline-block',
            width: 'calc(30% - 8px)',
            marginLeft: 8
          }}
        >
          <Select
            style={styles.select}
            placeholder={t('text_currency')}
            optionFilterProp="children"
            onChange={(value) => {
              setCurrency(value);
            }}
            value={currency}
          >
            {currencyOptions}
          </Select>
        </Form.Item>
        {!securePaymentData && (
          <Form.Item
            name="setToTerminal"
            valuePropName="checked"
            onChange={(e) => {
              setSetToTerminal(e.target.checked);
            }}
          >
            <Checkbox>
              Dirigir al terminal de la moneda seleccionada
            </Checkbox>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isDisabledSubmitModalForm()}
          onClick={handleOk}
          style={styles.buttonPrimaryModal}
        >
          {securePaymentData ? t('text_button_pay_secure') : t('text_button_generate_link')}
        </Button>
      </Form.Item>
    </Form>
  );
}

NewInvitationForm.defaultProps = {
  handleSubmit: undefined,
  securePaymentData: 0,
};

NewInvitationForm.propTypes = {
  handleSubmit: PropTypes.func,
  securePaymentData: PropTypes.number,
};
