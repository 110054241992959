import React from 'react';
import 'antd/dist/antd.less';
import {
  BankOutlined,
  BookOutlined,
  CreditCardOutlined,
  EuroCircleOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  TableOutlined,
  UnorderedListOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import PropTypes from 'prop-types';
import {
  getReadPermissionByTab,
  getWritePermissionByTab
} from '../../../utils/Utils';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import BurgerMenuItem from './BurgerMenuItem';

const menuBurgerStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '36px',
    height: '30px',
  },
  bmBurgerBars: {
    background: '#fff'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    right: 0,
    top: 0
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    height: 'fit-content',
  },
};

export default function BurgerMenu(props) {
  const { t } = useTranslation();
  const {changeIndex} = props;

  return (
    <Menu styles={menuBurgerStyles}>
      {getReadPermissionByTab(ConstantsRoutes.PAYMENTS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(1)}
            route={ConstantsRoutes.PAYMENTS}
            label={t('text_button_payments')}
            icon={(
              <TableOutlined />
            )}
          />
        </div>
      ) : null}

      {getReadPermissionByTab(ConstantsRoutes.INVITATIONS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(3)}
            route={ConstantsRoutes.INVITATIONS}
            label={t('text_button_list')}
            icon={(
              <UnorderedListOutlined />
                )}
          />
        </div>
      ) : null}

      {getReadPermissionByTab(ConstantsRoutes.TPV_SECURE_MANUAL) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(13)}
            route={ConstantsRoutes.TPV_SECURE_MANUAL}
            label={t('new_tpv_secure')}
            icon={(
              <UnorderedListOutlined />
                )}
          />
        </div>
      ) : null}

      {getWritePermissionByTab(ConstantsRoutes.INVITATIONS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(12)}
            route={ConstantsRoutes.INVITATION_ADD}
            label={t('new_invitation')}
            icon={(
              <FileAddOutlined />
                        )}
          />
        </div>

      ) : null}

      {getReadPermissionByTab(ConstantsRoutes.PRE_CONCILIATION) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(5)}
            route={ConstantsRoutes.PRE_CONCILIATION}
            label={t('text_button_pre_conciliation')}
            icon={(
              <ReconciliationOutlined />
                )}
          />
        </div>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(6)}
            route={ConstantsRoutes.CONCILIATIONS}
            label={t('text_button_conciliations')}
            icon={(
              <BankOutlined />
                )}
          />
        </div>
      ) : null}

      {getReadPermissionByTab(ConstantsRoutes.TPV_MANUAL) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(7)}
            route={ConstantsRoutes.TPV_MANUAL}
            label={t('text_manual_tpv')}
            icon={(
              <CreditCardOutlined />
                )}
          />
        </div>
      ) : null}

      {getReadPermissionByTab(ConstantsRoutes.TPV_TRANSACTIONS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(8)}
            route={ConstantsRoutes.TPV_TRANSACTIONS}
            label={t('text_tpv_transactions')}
            icon={(
              <ShopOutlined />
                )}
          />
        </div>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.COLLECTION_REQUESTS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(10)}
            route={ConstantsRoutes.COLLECTION_REQUESTS}
            label={t('collection_request')}
            icon={(
              <BookOutlined />
                    )}
          />
        </div>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.PAYMENT_REQUESTS) ? (
        <div>
          <BurgerMenuItem
            clickHandler={() => changeIndex(11)}
            route={ConstantsRoutes.PAYMENT_REQUESTS}
            label={t('payment_request')}
            icon={(
              <BookOutlined />
                        )}
          />
        </div>

      ) : null}
    </Menu>
  );
}

BurgerMenu.defaultProps = {
  changeIndex: undefined,
};

BurgerMenu.propTypes = {
  changeIndex: PropTypes.func,
};
