import React, { useEffect, useState } from 'react';
import {
  Button, Col, Collapse, DatePicker, Form, Input, message, Row, Select, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {actionLoading} from '../../../store/reducers/loading/loadingActions';
import Colors from '../../../assets/colors/Colors';
import ConstantsDate from '../../../constants/ConstantsDate';
import {
  actionGetFiltersPreConciliation, actionGetPreConciliationReservations
} from '../../../store/reducers/preConciliation/preConciliationActions';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

const styles = {
  input: {
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 14,
    paddingTop: 6,
    width: '100%',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginLeft: 8,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: '100%',
  },
  textTitle: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  rowFilter: {
    width: '100%',
    padding: 16,
    backgroundColor: Colors.BACKGROUND_COLOR_2,
  },
  textTitleDescription: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
  icon: {
    color: Colors.ICON_COLOR,
  },
  select: {
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
};

export default function FilterPreConciliation() {
  const [idTransaction, setIdTransaction] = useState('');
  const [numberLocator, setNumberLocator] = useState('');
  const [externalNumberLocator, setExternalNumberLocator] = useState('');

  const [stateReservation, setStateReservation] = useState('');
  const [payState, setPayState] = useState('');
  const [concept, setConcept] = useState('');
  const [channel, setChannel] = useState('');
  const [validations, setValidations] = useState('');
  const [currency, setCurrency] = useState('');
  const [chain, setChain] = useState('');
  const [nameHotel, setNameHotel] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState('');
  const [dateCreateEnd, setDateCreateEnd] = useState('');
  const [dateCheckinStart, setDateCheckinStart] = useState('');
  const [dateCheckinEnd, setDateCheckinEnd] = useState('');
  const [otaCode, setOtaCode] = useState('');
  const [user, setUser] = useState('');
  const [client, setClient] = useState('');
  const [crs, setCrs] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFiltersPreConciliation);
  const recalificationReservationsResult = useSelector(
    (state) => state.reducerApplyRecalificationReservationsResult
  );
  const changeStatusReservationsResult = useSelector(
    (state) => state.reducerChangeStatusReservationsResult
  );
  const deleteReclasificationReservationsResult = useSelector(
    (state) => state.reducerDeleteReclasificationReservationsResult
  );
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFiltersPreConciliation());
    }
  }, []);

  useEffect(() => {
    if (filters.msg !== null && filters.result === false) {
      message.error(filters.msg);
    }

    if (recalificationReservationsResult.result === true
      || changeStatusReservationsResult.result === true
      || deleteReclasificationReservationsResult.result === true) {
      dispatch(actionLoading(true));
      dispatch(actionGetPreConciliationReservations(
        idTransaction,
        numberLocator,
        stateReservation,
        payState,
        concept,
        channel,
        validations,
        chain,
        nameHotel,
        dateCreateStart.length === 0 ? '' : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
        dateCreateEnd.length === 0 ? '' : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
        dateCheckinStart.length === 0 ? '' : format(dateCheckinStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
        dateCheckinEnd.length === 0 ? '' : format(dateCheckinEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
        otaCode,
        user,
        client,
        crs,
        currency,
        externalNumberLocator
      ));
    }
  });

  const clickClearFilters = () => {
    setIdTransaction('');
    setNumberLocator('');
    setExternalNumberLocator('');
    setStateReservation('');
    setPayState('');
    setConcept('');
    setChannel('');
    setValidations('');
    setChain('');
    setNameHotel('');
    setDateCreateStart('');
    setDateCreateEnd('');
    setDateCheckinStart('');
    setDateCheckinEnd('');
    setOtaCode('');
    setUser('');
    setClient('');
    setCrs('');
    setCurrency('');
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    e.preventDefault();
    dispatch(actionLoading(true));
    dispatch(actionGetPreConciliationReservations(
      idTransaction,
      numberLocator,
      stateReservation,
      payState,
      concept,
      channel,
      validations,
      chain,
      nameHotel,
      dateCreateStart.length === 0 ? '' : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateCreateEnd.length === 0 ? '' : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateCheckinStart.length === 0 ? '' : format(dateCheckinStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateCheckinEnd.length === 0 ? '' : format(dateCheckinEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      otaCode,
      user,
      client,
      crs,
      currency,
      externalNumberLocator
    ));
  };

  const hotelsOptions = [];
  filters.hotels.filter((item) => !chain || item.id_cadena === chain).forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const channelsOptions = [];
  filters.channels.forEach((i) => {
    channelsOptions.push(
      <Option key={i.channel} value={i.channel}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const otaCodesOptions = [];
  filters?.payForms?.forEach((i) => {
    otaCodesOptions.push(
      <Option key={i.tipo} value={i.tipo}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const chainsOptions = [];
  filters.chains.forEach((i) => {
    chainsOptions.push(
      <Option key={i.id_cadena} value={i.id_cadena}>
        <Text style={styles.textOptionValue}>
          {i.nombre_cadena}
        </Text>
      </Option>,
    );
  });

  const conceptOptions = [];
  filters.concepts.forEach((i) => {
    conceptOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const crsOptions = [];
  filters?.crs?.forEach((i) => {
    crsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const validationsOptions = [];
  filters.validations?.forEach((i) => {
    validationsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const statesReservationOptions = [];
  filters.reservationStates.forEach((i) => {
    statesReservationOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const statesPayOptions = [];
  filters.payStates.forEach((i) => {
    statesPayOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const clientsOptions = [];
  filters?.clients?.forEach((i) => {
    clientsOptions.push(
      <Option key={i.cliente} value={i.cliente}>
        <Text style={styles.textOptionValue}>
          {`${i.cliente} - ${i.label}`}
        </Text>
      </Option>,
    );
  });

  const currencyOptions = [];
  filters.currencies.forEach((i) => {
    currencyOptions.push(
      <Option key={i.currency} value={i.currency}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  return (
    <Row align="middle" style={styles.rowFilter} className="remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text style={styles.textTitle}>
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="field1"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('hint_number_locator')}
                    onChange={(text) => {
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="crs"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_crs')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_crs')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCrs(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={crs}
                  >
                    {crsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreate"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_create_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? '' : dates[0]);
                      setDateCreateEnd(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="external_number_locator"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_external_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_external_booking_locator')}
                    onChange={(text) => {
                      setExternalNumberLocator(text.target.value);
                    }}
                    value={externalNumberLocator}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCheckin"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_check_in')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCheckinStart(dates === null ? '' : dates[0]);
                      setDateCheckinEnd(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="idTransaction"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_id_transaction')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_id_transaction')}
                    onChange={(text) => {
                      setIdTransaction(text.target.value);
                    }}
                    value={idTransaction}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="nameHotel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_hotel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('hint_hotel_name')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setNameHotel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={nameHotel}
                  >
                    {hotelsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="payForm"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_ota_code')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    style={styles.select}
                    dropdownStyle={{ minWidth: '40%' }}
                    allowClear
                    placeholder={t('text_ota_code')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setOtaCode(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={otaCode}
                  >
                    {otaCodesOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="stateReservation"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_reservation_state')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_reservation_state')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStateReservation(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={stateReservation}
                  >
                    {statesReservationOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="channel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_channel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('hint_channel')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChannel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={channel}
                  >
                    {channelsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('hint_user')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('hint_user')}
                    onChange={(text) => {
                      setUser(text.target.value);
                    }}
                    value={user}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="payState"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_pay_state')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_pay_state')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setPayState(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={payState}
                  >
                    {statesPayOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="client"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_client')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('text_client')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setClient(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    value={client}
                  >
                    {clientsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="validations"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_validations')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_validations')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setValidations(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={validations}
                  >
                    {validationsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="concept"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_concept')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_concept')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setConcept(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={concept}
                  >
                    {conceptOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="currency"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={currency}
                  >
                    {currencyOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        style={styles.buttonDefault}
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={clickFilterButton}
                        style={styles.buttonPrimary}
                        disabled={
                          idTransaction?.length === 0
                          && numberLocator?.length === 0
                          && externalNumberLocator?.length === 0
                          && stateReservation?.length === 0
                          && payState?.length === 0
                          && concept?.length === 0
                          && channel?.length === 0
                          && validations?.length === 0
                          && chain?.length === 0
                          && nameHotel?.length === 0
                          && dateCreateStart?.length === 0
                          && dateCreateEnd?.length === 0
                          && dateCheckinStart?.length === 0
                          && dateCheckinEnd?.length === 0
                          && otaCode?.length === 0
                          && user?.length === 0
                          && client?.length === 0
                          && crs?.length === 0
                          && currency?.length === 0
                        }
                      >
                        {t('text_button_filter')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
