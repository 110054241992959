import React, { useEffect } from 'react';
import {
  Card, Col, message, Row, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Colors from '../../../../assets/colors/Colors';
import { useLazyGetConciliationDataApiQuery } from '../../../../services/conciliationLines';

const { Text } = Typography;

const styles = {
  rowFilter: {
    width: '100%',
    padding: 16,
    backgroundColor: Colors.BACKGROUND_COLOR_2,
  },
  textTitle: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textTitleDescription: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    width: '100%',
    fontSize: 14,
    color: Colors.TEXT_COLOR,
  },
  textValue: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    width: '100%',
    fontSize: 24,
    color: Colors.TEXT_COLOR,
  },
  textTitleDescription2: {
    width: '100%',
    fontSize: 12,
    color: Colors.TEXT_COLOR,
    marginLeft: 16,
  },
  textValue2: {
    fontWeight: '300',
    width: '100%',
    fontSize: 18,
    color: Colors.TEXT_COLOR,
  },
  textPercent: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    fontSize: 12,
    color: Colors.TEXT_GREEN,
    marginLeft: 8,
  },
  textPercentNegative: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    fontSize: 12,
    color: Colors.TEXT_RED,
    marginLeft: 8,
  },
};

export default function ResumeConciliation() {
  const { t } = useTranslation();

  const [
    _,
    {
      data: conciliation,
      error: errorGetConciliationData
    }
  ] = useLazyGetConciliationDataApiQuery();

  useEffect(() => {
    if (errorGetConciliationData) {
      message.error(errorGetConciliationData.message);
    }
  }, [errorGetConciliationData]);
  /*
  return (
    <Row align="middle" style={styles.rowFilter} className="remaquia">
      <Card
        title={(
          <Text style={styles.textTitle}>
            {conciliation?.result ? conciliation?.conciliation?.header?.titulo : ''}
          </Text>
        )}
        extra={(
          <Row justify="space-between">
            <Col>
              <Text style={styles.textTitleDescription2}>
                {`${t('text_init_date')}: ${conciliation?.result ? conciliation?.conciliation?.header?.fecha_inicio : ''}`}
              </Text>
            </Col>
            <Col>
              <Text style={styles.textTitleDescription2}>
                {`${t('text_end_date')}: ${conciliation?.result ? conciliation?.conciliation?.header?.fecha_final : ''}`}
              </Text>
            </Col>
          </Row>
        )}
        style={{ width: '100%' }}
      >
        <Row gutter={24} justify="space-between">
          <Col span={4}>
            <Row>
              <Text style={styles.textTitleDescription}>
                {t('text_total_hotel_collect')}
              </Text>
            </Row>
            <Row align="bottom">
              <Text style={styles.textValue}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'EUR',
                }).format(Number(conciliation?.conciliation?.totales[0]?.total_euros_hotel ?? 0)) : ''}
              </Text>
            </Row>
            <Row>
              <Text style={styles.textValue2}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number(
                  conciliation?.conciliation?.totales[0]?.total_dolares_hotel ?? 0
                )) : ''}
              </Text>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Text style={styles.textTitleDescription}>
                {t('text_total_adg_collect')}
              </Text>
            </Row>
            <Row align="bottom">
              <Text style={styles.textValue}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'EUR',
                }).format(Number(conciliation?.conciliation?.totales[0]?.total_euros_adg ?? 0)) : ''}
              </Text>
            </Row>
            <Row>
              <Text style={styles.textValue2}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number(conciliation?.conciliation?.totales[0]?.total_dolares_adg ?? 0)) : ''}
              </Text>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Text style={styles.textTitleDescription}>
                {t('text_commission_hotel_collect')}
              </Text>
            </Row>
            <Row align="bottom">
              <Text style={styles.textValue}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'EUR',
                }).format(
                  Number(conciliation?.conciliation?.totales[0]?.total_comision_euros_hotel ?? 0)
                ) : ''}
              </Text>
            </Row>
            <Row>
              <Text style={styles.textValue2}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'USD',
                }).format(
                  Number(
                    conciliation?.conciliation?.totales[0]?.total_comision_dolares_hotel ?? 0
                  )
                ) : ''}
              </Text>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Text style={styles.textTitleDescription}>
                {t('text_commission_adg_collect')}
              </Text>
            </Row>
            <Row align="bottom">
              <Text style={styles.textValue}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'EUR',
                }).format(
                  Number(conciliation?.conciliation?.totales[0]?.total_comision_euros_adg ?? 0)
                ) : ''}
              </Text>
            </Row>
            <Row>
              <Text style={styles.textValue2}>
                {conciliation?.result ? new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: 'USD',
                }).format(
                  Number(conciliation?.conciliation?.totales[0]?.total_comision_dolares_adg ?? 0)
                ) : ''}
              </Text>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
  */
  return <div> </div>;
}
