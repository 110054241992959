// #region Imports
import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeTwoTone,
  FilePdfOutlined,
  RightCircleOutlined,
  SendOutlined,
  UpCircleOutlined,
  FilePdfTwoTone,
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import i18n from '../../../assets/language/i18n';
import FilterConciliations from './listConciliationsAux/FilterConciliations';
import Colors from '../../../assets/colors/Colors';
import PreBills from './listConciliationsAux/PreBills';
import ConstantsDate from '../../../constants/ConstantsDate';
import NewConciliationModal from './listConciliationsAux/NewConciliationModal';
import {
  actionCancelConciliation,
  actionClearCompanies,
  actionClearNewPreconciliationResult,
  actionGetAllPreBillPdf,
  actionSendAllBill,
  actionSendAllPreBill,
  actionClearConciliationPrevisualizationResult,
  actionGetSalePdf
} from '../../../store/reducers/conciliations/conciliationsActions';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import { getWritePermissionByTab } from '../../../utils/Utils';
import {addFilterItemToArray } from '../../../utils/helperFunctions';
import ConciliationActionButtons from './listConciliationsAux/ConciliationActionButtons';
import { useSendMultipleBillsMutation, useSendMultiplePreBillsMutation } from '../../../services/asyncProcess';
import { SEND_BILL_KEY, SEND_PRE_BILL_KEY } from '../../../constants/ConstantsAsyncProcess';
// #endregion

const { Text } = Typography;
const { TextArea } = Input;

const styles = {
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
};

let modalCommentInput = '';

export default function Conciliations() {
  const navigate = useNavigate();

  const [showModalNewPreconciliation, setShowModalNewPreconciliation] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const conciliations = useSelector((state) => state.reducerConciliations);
  const applyFilters = useSelector((state) => state.reducerFiltersConciliations);
  const sendPreBillResult = useSelector((state) => state.reducerSendPreBillResult);
  const sendAllBillResult = useSelector((state) => state.reducerSendAllBillResult);
  const loading = useSelector((state) => state.reducerLoading);

  const [sendMultiplePreBills, {
    isLoading: isLoadingSendMultiplePreBills,
    error: errorSendMultiplePreBills,
    isSuccess: isSuccessSendMultiplePreBills,
    data: dataSendMultiplePreBills,
  }] = useSendMultiplePreBillsMutation();

  const [sendMultipleBills, {
    isLoading: isLoadingSendMultipleBills,
    error: errorSendMultipleBills,
    isSuccess: isSuccessSendMultipleBills,
    data: dataSendMultipleBills,
  }] = useSendMultipleBillsMutation();

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  // #region actions handlers
  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const openConfirmationModal = (label, okHandler) => {
    const { confirm } = Modal;
    confirm({
      title: label,
      icon: <ExclamationCircleOutlined />,
      content: (<TextArea
        showCount
        allowClear
        rows={3}
        maxLength={130}
        onChange={(e) => {
          modalCommentInput = e.target.value;
        }}
      />),
      onOk: okHandler,
    });
  };

  const sendSinglePreBillHandler = (idJob) => {
    openConfirmationModal(
      t('text_send_pre_facturations'),
      () => {
        dispatch(actionSendAllPreBill(idJob, modalCommentInput));
        modalCommentInput = '';
      }
    );
  };

  const asyncProcessClickHandler = (e) => {
    switch (e) {
      case SEND_PRE_BILL_KEY:
        openConfirmationModal(
          t('text_send_pre_facturations'),
          () => {
            sendMultiplePreBills({ idJobs: selectedRowKeys, message: modalCommentInput });
            modalCommentInput = '';
          }
        );
        break;
      case SEND_BILL_KEY:
        openConfirmationModal(
          t('text_send_facturations'),
          () => {
            sendMultipleBills({ idJobs: selectedRowKeys, message: modalCommentInput });
            modalCommentInput = '';
          }
        );
        break;
      default:
        break;
    }
  };

  const sendBillByMailHandler = (idJob) => {
    openConfirmationModal(
      t('text_send_facturations'),
      () => {
        dispatch(actionSendAllBill(idJob, modalCommentInput));
        modalCommentInput = '';
      }
    );
  };

  const cancelConciliationHandler = (idJob) => {
    openConfirmationModal(
      t('text_cancel_concilitation'),
      () => {
        dispatch(actionCancelConciliation(idJob, modalCommentInput));
        modalCommentInput = '';
      }
    );
  };

  // #endregion

  // #region variables reactivas (useEffect)
  useEffect(() => {
    dispatch(actionClearCompanies());
  }, []);

  useEffect(() => {
    if (conciliations.msg !== null && conciliations.result === false) {
      message.error(conciliations.msg);
    }
    if (sendPreBillResult?.msg !== null && sendPreBillResult?.result === false) {
      message.error(sendPreBillResult.msg);
    }
    if (sendPreBillResult.result === true) {
      message.success(sendPreBillResult.msg);
    }
    if (sendAllBillResult?.msg !== null && sendAllBillResult?.result === false) {
      message.error(sendAllBillResult.msg);
    }
    if (sendAllBillResult.result === true) {
      message.success(sendAllBillResult.msg);
    }
  });

  useEffect(() => {
    if (isSuccessSendMultiplePreBills) {
      message.success(t('success_send_pre_bills_process'));
      setSelectedRowKeys([]);
    }
    if (errorSendMultiplePreBills) {
      message.error(errorSendMultiplePreBills?.message);
    }
  }, [isSuccessSendMultiplePreBills, errorSendMultiplePreBills]);

  useEffect(() => {
    if (isSuccessSendMultipleBills) {
      message.success(t('success_send_bills_process'));
      setSelectedRowKeys([]);
    }
    if (errorSendMultipleBills) {
      message.error(errorSendMultipleBills?.message);
    }
  }, [isSuccessSendMultipleBills, errorSendMultipleBills]);

  // #endregion

  // #region table filters
  const filtersSelected = filteredInfo || {};

  const statusFilters = [];
  const groupFilters = [];
  const hotelFilters = [];

  conciliations.forEach((conciliation) => {
    if (conciliation.estado !== null) {
      addFilterItemToArray(statusFilters, conciliation.estado, applyFilters?.conciliationStates?.find(
        (item) => item.value === conciliation.estado
      )
        ?.label);
    }
    if (conciliation.id_empresa !== null) {
      addFilterItemToArray(groupFilters, conciliation.id_empresa, applyFilters?.facturationGroups?.find(
        (item) => item.value === conciliation.id_empresa
      )
        ?.label);
    }
    conciliation.prefacturas?.forEach((i) => {
      addFilterItemToArray(hotelFilters, i?.id_hotel, i?.nombre_hotel);
    });
  });

  statusFilters.sort((a, b) => a?.text?.localeCompare(b?.text, i18n.language));
  groupFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  hotelFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));

  // #endregion

  const columns = [
    {
      title: <Text className="text">{t('text_conciliation_status')}</Text>,
      dataIndex: 'estado',
      key: 'estado',
      sorter: {
        compare: (a, b) => a.estado.localeCompare(b.estado, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected.estado || null,
      filters: statusFilters,
      onFilter: (value, record) => record.estado?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip
          title={
            applyFilters.conciliationStates.find((item) => item.tipo === text)?.label
            }
        >
          <Text className="text">
            {
              applyFilters?.conciliationStates?.find((item) => item.value === text)?.label
              }
          </Text>
        </Tooltip>
      ),
    },
    {
      title: <Text className="text">{t('text_group')}</Text>,
      dataIndex: 'id_empresa',
      key: 'id_empresa',
      sorter: {
        compare: (a, b) => a.id_empresa?.localeCompare(b.id_empresa, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected.id_empresa || null,
      filters: groupFilters,
      onFilter: (value, record) => record.id_empresa?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {applyFilters?.facturationGroups?.find((item) => item.value === text)?.label}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_hotel_hotels')}</Text>,
      dataIndex: 'prefacturas',
      key: 'prefacturas',
      filteredValue: filtersSelected.prefacturas || null,
      filters: hotelFilters,
      onFilter: (
        value,
        record
      ) => !!record.prefacturas?.find((item) => item.id_hotel === value),
      responsive: ['md'],
      render: (text) => (
        <Tooltip placement="bottom" title={[...new Set(text?.map((item) => item.nombre_hotel))].join(', ')}>
          <div className="max-w-[300px] whitespace-nowrap text-ellipsis overflow-hidden" style={styles.truncateTextContainer}>
            <Text className="text">
              {[...new Set(text?.map((item) => item.nombre_hotel))].join(', ')}
            </Text>
          </div>
        </Tooltip>
      ),
    },
    {
      title: <Text className="text">{t('text_currency')}</Text>,
      dataIndex: 'moneda',
      key: 'moneda',
      filteredValue: filtersSelected.moneda || null,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {applyFilters?.moneda?.find((item) => item.value === text)?.label}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_start_date')}</Text>,
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
      sorter: {
        compare: (a, b) => a.fecha_inicio - b.fecha_inicio,
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length
            ? ''
            : format(text, ConstantsDate.DATE_FORMAT, {
              locale: es,
            })}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_end_date')}</Text>,
      dataIndex: 'fecha_final',
      key: 'fecha_final',
      sorter: {
        compare: (a, b) => a.fecha_final - b.fecha_final,
        multiple: 8,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length
            ? ''
            : format(text, ConstantsDate.DATE_FORMAT, {
              locale: es,
            })}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_actions')}</Text>,
      dataIndex: 'id_job',
      key: 'id_job',
      responsive: ['md'],
      render: (idJob, record) => (
        <div>
          {getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
            <Tooltip title={t('cancel_conciliation')}>
              <Button
                type="link"
                htmlType="button"
                disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS)}
                onClick={() => cancelConciliationHandler(idJob)}
                icon={<DeleteOutlined style={{ color: Colors.TEXT_RED, marginRight: '7px', fontSize: '20px' }} />}
                style={styles.buttonLink}
              />
            </Tooltip>
          ) : <div />}
          <Tooltip title={t('visualize_prebill')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                navigate(ConstantsRoutes.CONCILIATION, {
                  state: {
                    jobId: idJob,
                    hotelId: null,
                    hotelName: null,
                    companyName: applyFilters?.facturationGroups?.find((item) => item.value === record.id_empresa)?.label,
                  },
                });
              }}
              icon={<EyeTwoTone style={{ color: Colors.OTHERS, marginRight: '7px', fontSize: '20px' }} />}
              style={styles.buttonLink}
            />
          </Tooltip>
          <Tooltip title={t('text_sale_pdf_download')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                dispatch(actionGetSalePdf(
                  idJob,
                  `sale_${idJob}`
                ));
              }}
              icon={<FilePdfTwoTone style={{ color: Colors.PAY_RETURN, fontSize: '20px' }} />}
              style={styles.buttonLink}
            />

          </Tooltip>
          <Tooltip title={t('text_download_facturations')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                const fechaInicio = record?.fecha_inicio?.length ? ''
                  : format(record?.fecha_inicio, ConstantsDate.DATE_FORMAT_FILE_NAME, {
                    locale: es,
                  });
                const fechaFinal = record?.fecha_final?.length ? ''
                  : format(record?.fecha_final, ConstantsDate.DATE_FORMAT_FILE_NAME, {
                    locale: es,
                  });
                dispatch(actionGetAllPreBillPdf(
                  idJob,
                  `${record?.nombre?.replaceAll(' ', '-')}_${fechaInicio}_${fechaFinal}`
                ));
              }}
              icon={<FilePdfOutlined style={{ color: Colors.PAY_RETURN, fontSize: '20px' }} />}
              style={styles.buttonLink}
            />
          </Tooltip>
          <Divider
            style={{ fontSize: '20px' }}
            type="vertical"
          />
        </div>
      ),
    },
  ];

  const isLoading = loading || isLoadingSendMultiplePreBills || isLoadingSendMultipleBills;

  return (
    <Spin spinning={isLoading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          {showModalNewPreconciliation ? (
            <NewConciliationModal
              handleOk={() => {
                setShowModalNewPreconciliation(false);
                dispatch(actionClearNewPreconciliationResult());
                dispatch(actionClearConciliationPrevisualizationResult());
              }}
              handleCancel={() => {
                setShowModalNewPreconciliation(false);
                dispatch(actionClearNewPreconciliationResult());
                dispatch(actionClearConciliationPrevisualizationResult());
              }}
              visible={showModalNewPreconciliation}
            />
          ) : (
            ''
          )}
          <FilterConciliations />
          <ConciliationActionButtons
            hasSelected={hasSelected}
            setShowModalNewPreconciliation={setShowModalNewPreconciliation}
            clearAllFilters={clearAllFilters}
            conciliations={conciliations}
            asyncProcessClickHandler={asyncProcessClickHandler}
          />

          <Table
            rowKey="id_job"
            style={{ width: '100%' }}
            pagination={{ total: conciliations.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'], showSizeChanger: true }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={conciliations}
            onChange={handleChange}
            rowSelection={rowSelection}
            expandable={{
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) => (
                expanded ? (
                  <UpCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    style={styles.icon}
                  />
                ) : (
                  <RightCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    style={styles.icon}
                  />
                )
              ),
              expandedRowRender: (record) => (
                <PreBills conciliation={record} />
              ),
              rowExpandable: () => true,
            }}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={conciliations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + conciliations.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
