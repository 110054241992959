import React from 'react';
import {
  Col,
  Row,
  Typography,
} from 'antd';
import 'antd/dist/antd.less';

import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Colors from '../../../assets/colors/Colors';

const { Text } = Typography;

const styles = {
  textActive: {
    fontSize: 12,
    color: Colors.PRIMARY_COLOR,
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  textInactive: {
    fontSize: 12,
    color: 'white',
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  lineActive: {
    backgroundColor: Colors.PRIMARY_COLOR,
    height: 3,
    width: '100%'
  },
  icon: { paddingBottom: '5px' },
  lineInactive: { backgroundColor: 'transparent', height: 3, width: '100%' },
  tab: { width: '100px', marginBot: '10px'},
};

export default function TopBarGroup(props) {
  const {
    route,
    label,
    icon
  } = props;

  const location = useLocation();
  const isActive = Array.isArray(route)
    ? route.includes(location.pathname)
    : location.pathname === route;
  return (
    <Col
      style={styles.tab}
    >
      <Row justify="center" style={{paddingBottom: '11px'}}>
        <div style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          {icon}
        </div>
      </Row>
      <Row justify="center">
        <Text
          style={isActive ? styles.textActive : styles.textInactive}
        >
          {label}
        </Text>
      </Row>
      <Row justify="center">
        <div
          style={
                      location.pathname === route
                        ? styles.lineActive
                        : styles.lineInactive
                    }
        />
      </Row>
    </Col>
  );
}

TopBarGroup.defaultProps = {
  route: undefined,
  label: undefined,
  icon: undefined
};

TopBarGroup.propTypes = {
  route: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string,
  icon: PropTypes.element,
};
