import React, { useEffect, useState } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Transfer,
  Typography,
  Button
} from 'antd';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Colors from '../../../../assets/colors/Colors';
import ConstantsDate from '../../../../constants/ConstantsDate';
import {
  actionClearCompanies,
  actionGetCompanyByChain,
  actionGetConciliations,
  actionPrevisualizeConciliation,
} from '../../../../store/reducers/conciliations/conciliationsActions';
import PrevisualizationConciliationCard from '../previsualizationConciliation/PrevisualizationConciliationCard';
import { useCreatePreconciliationMutation } from '../../../../services/asyncProcess';

const styles = {
  container: {
    padding: '0 20px',
  },
  listStyle: {
    width: '250px',
  },
  spinContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    paddingTop: 6,
    width: '100%',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginLeft: 8,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: '100%',
  },
  textTitle: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textTitleDescription: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
  marginSelector: {
    marginRight: 16, /* Ajusta el valor según el espacio que necesites */
  },
  select: {
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
};

export default function NewConciliationModal(props) {
  const [jobName, setJobName] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState('');
  const [dateCreateEnd, setDateCreateEnd] = useState('');
  const [chain, setChain] = useState(null);
  const [allowCup, setAllowCup] = useState(true);
  const [targetCompanies, setTargetCompanies] = useState([]);
  const [moneda, setMoneda] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFiltersConciliations);
  const companies = useSelector((state) => state.reducerGetCompanyByChain);
  const newConciliationResult = useSelector((state) => state.reducerAddNewPreconciliation);
  const loading = useSelector((state) => state.reducerLoading);
  const [monedaOptions, setMonedaOptions] = useState([]);
  const conciliationPrevisualization = useSelector((state) => state.reducerPrevisualizeConciliation);

  const {
    handleOk,
    handleCancel,
    visible
  } = props;

  useEffect(() => {
    if (newConciliationResult.result) {
      dispatch(
        actionGetConciliations(
          '',
          '',
          '',
          '',
          '',
          chain
        )
      );
      clearCompanies();
      handleOk();
    }
    if (
      newConciliationResult.msg !== null
      && newConciliationResult.result === false
    ) {
      message.error(newConciliationResult.msg);
    }
  });
  useEffect(() => {
    // Suponemos que filters?.moneda es la lista de monedas que recibes de la API
    updateMonedaOptions(true);
  }, [filters?.moneda]);

  const companiesForTransfer = Array.isArray(companies) && companies?.length ? companies : [];

  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const handleChange = (targetKeys) => {
    setTargetCompanies(targetKeys);
  };
  const handleChainChange = (selectedChainValue) => {
    setChain(selectedChainValue);
    if (!selectedChainValue) {
      setAllowCup(true);
      setMoneda('');
      return;
    }
    dispatch(actionGetCompanyByChain(selectedChainValue));
    setTargetCompanies([]);
    const selectedChain = filters?.chains?.find((chainItem) => chainItem.id_cadena === selectedChainValue);
    if (selectedChain) {
      const allowsCup = selectedChain.concilia_cup === '1';
      setAllowCup(allowsCup);
      if (!allowsCup && moneda === 'cup') {
        setMoneda('eur');
        message.warning('La cadena seleccionada no permite CUP. Se ha cambiado a EUR/USD.');
      }
      if (!allowsCup && !moneda) {
        setMoneda('eur');
      }
      updateMonedaOptions(allowsCup);
    }
  };
  const handleMonedaChange = (value) => {
    setMoneda(value);
  };

  const clearCompanies = () => {
    setTargetCompanies([]);
    dispatch(actionClearCompanies());
  };

  const renderItem = (item) => {
    const customLabel = <span>{item.nombre_empresa}</span>;

    return {
      label: customLabel, // for displayed item
      value: item.nombre_empresa?.toLowerCase(), // for title and filter matching
    };
  };

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };

  const onCheckHandler = () => {
    dispatch(
      actionPrevisualizeConciliation(
        jobName,
        targetCompanies,
        dateCreateStart.length === 0
          ? ''
          : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
            locale: es,
          }),
        dateCreateEnd.length === 0
          ? ''
          : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
            locale: es,
          })
      )
    );
  };

  const [createPreconciliation, {
    isLoading: isLoadingCreatePreconciliation,
    error: errorCreatePreconciliation,
    isSuccess: isSuccessCreatePreconciliation,
    // data: dataCreatePreconciliation,
  }] = useCreatePreconciliationMutation();

  const onFinishForm = () => {
    createPreconciliation({
      idCompanies: targetCompanies,
      name: jobName,
      currency: moneda,
      startDate: dateCreateStart.length === 0
        ? ''
        : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
      endDate: dateCreateEnd.length === 0
        ? ''
        : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
    });

    // dispatch(
    //   actionAddNewPreconciliation(
    //     jobName,
    //     targetCompanies,
    //     dateCreateStart.length === 0
    //       ? ''
    //       : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
    //         locale: es,
    //       }),
    //     dateCreateEnd.length === 0
    //       ? ''
    //       : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
    //         locale: es,
    //       })
    //   )
    // );
  };

  useEffect(() => {
    if (errorCreatePreconciliation) {
      message.error('Error al crear la conciliación');
      console.log(errorCreatePreconciliation);
    }
  }, [errorCreatePreconciliation]);

  useEffect(() => {
    if (isSuccessCreatePreconciliation) {
      message.success('Se han iniciado los procesos para la creación de la preconciliación');
      handleOk();
    }
  }, [isSuccessCreatePreconciliation]);
  const updateMonedaOptions = (allowsCup) => {
    const monedaOptionsLocal = [];
    filters?.moneda?.forEach((i) => {
      if (i.value === 'cup' && !allowsCup) {
        return;
      }
      monedaOptionsLocal.push(
        (
          <Option key={i.value} value={i.value}>
            <Text style={styles.textOptionValue}>
              {i.label}
            </Text>
          </Option>
        )
      );
    });
    setMonedaOptions(monedaOptionsLocal);
  };
  const chainsOptions = [];
  filters?.chains?.forEach((i) => {
    chainsOptions.push(
      (
        <Option key={i.id_cadena} value={i.id_cadena}>
          <Text style={styles.textOptionValue}>{i.nombre_cadena}</Text>
        </Option>
      )
    );
  });
  chainsOptions.push(
    (
      <Option key="Todas" value={null}>
        <Text style={styles.textOptionValue}> Todas </Text>
      </Option>
    )
  );
  return (
    <Spin spinning={isLoadingCreatePreconciliation}>
      <Modal
        title={t('text_new_preconciliation')}
        open={visible}
        confirmLoading={loading}
        width={800}
        footer={[
          <Button
            onClick={() => {
              clearCompanies();
              handleCancel();
            }}
            key="cancel"
          >
            Cancelar
          </Button>,
          <Button
            onClick={onCheckHandler}
            key="previsualize"
            type="primary"
          >
            Previsualizar conciliación
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onOk}
          >
            {t('text_create_preconciliation')}
          </Button>,
        ]}
        onCancel={() => {
          clearCompanies();
          handleCancel();
        }}
      >
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          labelCol={{
            span: 4,
          }}
          onFinish={onFinishForm}
        >
          <div style={styles.container}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="nameJob"
                  label={
                    <Text style={styles.textTitleDescription}>{t('name')}</Text>
                }
                  rules={[
                    {
                      required: true,
                      message: t('requiredName'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('preconciliationName')}
                    onChange={(value) => {
                      setJobName(value.target.value);
                    }}
                    value={jobName}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="checkIn"
                  label={
                  (
                    <Text style={styles.textTitleDescription}>
                      {t('check_in_range')}
                    </Text>
                  )
                }
                  rules={[
                    {
                      required: true,
                      message: t('check_in_range_required'),
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? '' : dates[0]);
                      setDateCreateEnd(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="chain"
                  label={
                  (
                    <Text style={styles.textTitleDescription}>
                      {t('text_chain')}
                    </Text>
                  )
                }
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    style={styles.select}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    placeholder={t('text_chain')}
                    onChange={handleChainChange}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="moneda"
                  label={t('text_currency_conciliation')}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={styles.select}
                    allowClear
                    placeholder={t('text_currency_conciliation')}
                    onChange={handleMonedaChange}
                    value={moneda}
                    disabled={!allowCup && moneda === 'cup'} // Deshabilitar si la cadena no permite CUP
                  >
                    {/* Mostrar solo las opciones permitidas */}
                    {monedaOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="companies"
                  label={
                  (
                    <Text style={styles.textTitleDescription}>
                      {t('companies')}
                    </Text>
                  )
                }
                  rules={[
                    {
                      validator: () => (targetCompanies?.length
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('companies_required')))),
                    },
                  ]}
                >
                  <Spin spinning={loading}>
                    <div style={styles.spinContainer}>
                      <Transfer
                        rowKey={(record) => record.id_empresa}
                        dataSource={companiesForTransfer}
                        showSearch
                        titles={[t('companies'), t('companies_selected')]}
                        render={renderItem}
                        style={styles.transfer}
                        listStyle={styles.listStyle}
                        onChange={handleChange}
                        targetKeys={targetCompanies}
                      />
                    </div>
                  </Spin>
                </Form.Item>
              </Col>
            </Row>

            {conciliationPrevisualization.result ? (
              <Row gutter={24}>
                <Col span={24}>
                  <PrevisualizationConciliationCard logs={conciliationPrevisualization.logs} />
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        </Form>
      </Modal>
    </Spin>
  );
}

NewConciliationModal.defaultProps = {
  visible: false,
  handleOk: undefined,
  handleCancel: undefined,
};

NewConciliationModal.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
